@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

@tailwind base;

@tailwind components;

@tailwind utilities;


body{
  background:#161616;
}

.Header__Link {
  @apply text-[#E0EDE0] font-chakra font-semibold uppercase text-[15px] cursor-pointer relative pb-1 after:bg-main transition-all after:absolute after:bottom-0 after:right-[50%] after:h-[2px] after:transition-all after:ease-linear after:w-0 after:duration-300 lg:hover:after:w-[50%] before:bg-main duration-300 tracking-[1px] before:absolute before:bottom-0 before:left-[50%] before:h-[2px] before:transition-all before:ease-linear before:w-0 before:duration-300 lg:hover:before:w-[50%] hover:text-main
  
}
.Number {
  padding: 10px 30px;
  clip-path: polygon(20px 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%, 0 20px);
  overflow: hidden;
  border: 2px solid #d16527;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  position: relative;
}

.Number::after {
  position: absolute;
  content: "";
  right: 50%;
  top: 0;
  width: 0;
  height: 100%;
  background: #d16527;
  transition: all .3s linear;
  z-index: 0;
}

.Number:hover::after {
  width: 100%;
}

.Number::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 0;
  width: 0;
  height: 100%;
  background: #d16527;
  transition: all .3s linear;
  z-index: 0;
}

.Number:hover::before {
  width: 100%;
}

.Open {
  @apply fixed top-0 left-0 bg-bg-dark-light h-[100vh] py-24 transition-all z-50 duration-300 max-lg:w-64 w-0 overflow-hidden;
}

.Open {
  @apply z-50;
}

.Close {
  z-index: 9999999999999999999999 !important;

}

.Close {
  @apply fixed top-0 left-0 bg-bg-dark-light h-[100vh] py-24 transition-all duration-300 w-0 overflow-hidden;
}

.circle {
  width: 40px;
  height: 40px;
  background: #d16527;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
  pointer-events: none;
  mix-blend-mode: multiply;
  z-index: 10;
}

.Service__Button {
  font-size: 18px;
  line-height: 1.3em;
  background-color: #d16527;
  border: 2px solid #d16527;
  clip-path: polygon(20px 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%, 0 20px);
  border: 2px solid #d16527;
  transition: all .5s linear;
  padding: 18px 39px 15px;
}

.Service__Button {
  font-size: 18px;
  line-height: 1.3em;
  background-color: #d16527;
  border: 2px solid #d16527;
  clip-path: polygon(20px 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%, 0 20px);
  border: 2px solid #d16527;
  transition: all .5s linear;
  padding: 18px 39px 15px;
}

.Service__Button__Small {
  font-size: 15px;
  line-height: 1.3em;
  background-color: #d16527;
  border: 2px solid #d16527;
  clip-path: polygon(5px 0, 100% 0, 100% calc(100% - 5px), calc(100% - 5px) 100%, 0 100%, 0 5px);
  border: 2px solid #d16527;
  transition: all .5s linear;
  padding: 0px 2px;
  width: fit-content;
  color: #E0EDE0;
}

.link__span {
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to right, #d16527, #d16527 50%, #E0EDE0 50%);
  background-size: 200% 100%;
  background-position: 100%;
  position: relative;
  font-size: 16px;
}

.BTN__HOVER .link__span:hover {
  transition: all 0.5s cubic-bezier(0, 0, 0.23, 1);
  background-position: 0%;
}

.Service__Button {
  @apply overflow-hidden font-chakra font-bold text-white w-fit
}

.Service__Button:hover {
  clip-path: none;
  background: transparent
}

.Service__Button::after {
  content: '';
  position: absolute;
  top: -110%;
  left: -210%;
  width: 400%;
  height: 150%;
  opacity: 0;
  transition: all .5s linear;
  transform: rotate(30deg);
  background: rgba(255, 255, 255, .25);
  background: linear-gradient(to right, rgba(255, 255, 255, .25) 0%, rgba(255, 255, 255, .13) 77%, rgba(255, 255, 255, .5) 92%, #fff 100%);
}

.Service__Button:hover::after {
  top: 0;
  left: 0;
  opacity: 1
}

.Absolute__Text {
  position: absolute;
  color: transparent;
  line-height: 1;
  margin-bottom: 0;
  text-stroke-color: #242424;
  -webkit-text-stroke-color: #242424;
  text-stroke-width: 2px;
  -webkit-text-stroke-width: 2px;
  z-index: -1;
  top: 0;
  text-align: center;
  width: 100%;
  left: 0;
}
.Absolute__Text_Second {
  position: absolute;
  color: transparent;
  line-height: 1;
  margin-bottom: 0;
  text-stroke-color: #242424;
  -webkit-text-stroke-color: #242424;
  text-stroke-width: 2px;
  -webkit-text-stroke-width: 2px;
  z-index:0;
  top: 0;
  width: 100%;
  left: 0;
  top:40px;
  font-family:'Chakra Petch', 'sans-serif'; 
}
.Absolute__Text_Third {
  position: absolute;
  color: transparent;
  line-height: 1;
  margin-bottom: 0;
  text-stroke-color: #242424;
  -webkit-text-stroke-color: #242424;
  text-stroke-width: 2px;
  -webkit-text-stroke-width: 2px;
  z-index:0;
  top: 0;
  width: 100%;
  left: 0;
  top:-20px;
  font-family:'Chakra Petch', 'sans-serif'; 
}

.sticky-nav {
  position: sticky;
  top: 0;
  left: 0;
  transition: all 1.5s linear;
  background-color: black;
  z-index: 99999999999999999999;
  width:"100%"
}

.ImageFilterHover {
  transition: all .3s linear;
}

.GroupFilterHover:hover .ImageFilterHover {
  filter: invert(43%) sepia(75%) saturate(506%) hue-rotate(339deg) brightness(94%) contrast(97%);
}